import 'bootstrap'
import '../sass/main.scss'

import 'isotope-packery';

import 'jquery.scrollto';

import Preloader from "./preloader";
import Navigation from "./navigation";
import Home from "./home";
import Projects from "./projects";
import Office from "./office";
import Contact from "./contact";
import Modal from "./modal";


(function (   )
{
    var pagename = $("body").data("pagename");
    var pagelayer = $("body").data("layer");
    var timeout;


    $(document).ready(function()
    {
        var preloaderModul = new Preloader();
        preloaderModul.init();
        preloaderModul.hidePreloader();

        var navigationModul = new Navigation();
        navigationModul.init();

        var modalModul = new Modal();
        modalModul.init();

        if(pagename == "home")
        {
            var homeModul = new Home();
            homeModul.init();

            console.log("pagelayer = " + pagelayer);

            if(pagelayer == "impressum")
            {
                timeout = setTimeout(function()
                {
                    $('#m_imprint').modal('show');

                }, 100);

            }
            if(pagelayer == "datenschutz")
            {
                timeout = setTimeout(function()
                {
                    $('#m_privacy').modal('show');

                }, 100);

            }
        }

        if(pagename == "projects")
        {
            var projectsModul = new Projects();
            projectsModul.init();
        }

        if(pagename == "office")
        {
            var officeModul = new Office();
            officeModul.init();
        }

        if(pagename == "contact")
        {
            var contactModul = new Contact();
            contactModul.init();
        }




        // show impressum
        if( getUrlParameter("modal") == "impressum" )
        {
            $('#m_imprint').modal('show');
        }

        // show datenschutz
        if( getUrlParameter("modal") == "datenschutz" )
        {
            $('#m_privacy').modal('show');
        }


        // -------------------------------------------
        function initistener()
        {

        }


        // -------------------------------------------
        $(window).on('resize', function()
        {
            var winWidth = $(window).width();
            var winHeight = $(window).height();
            //$(".debug").html(winWidth + " / " + winHeight);
        });
        $(window).resize();



        // -------------------------------------------
        function getUrlParameter(sParam)
        {
            var sPageURL = window.location.search.substring(1),
                sURLVariables = sPageURL.split('&'),
                sParameterName,
                i;

            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');

                if (sParameterName[0] === sParam) {
                    return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
                }
            }
        };


    });
})();



