function preloaderModul()
{
    'use strict';

    var preloaderTimeout;
    var preloader = $('#preloader');


    // -------------------------------------------
    this.init = function()
    {
        initListener();
    };

    this.hidePreloader = function()
    {
        preloaderTimeout = setTimeout(function ()
        {
            $(".wrapper").addClass("show");

            preloader.css('top', -$(window).height());

            var timeout = setTimeout(function ()
            {
                preloader.addClass("hide");

            }, 1000);
        }, 1500);
    };

    // -------------------------------------------
    function initListener()
    {

    }
}

module.exports = preloaderModul;
