function modalModul()
{
    'use strict';


    // -------------------------------------------
    this.init = function()
    {
        // deeplink policy
        if(window.location.hash)
        {
            var hash = window.location.hash;
            if(hash == "#m_privacy")
            {
                $(hash).modal('toggle');
            }
        }

        // TEST
        //$('#m_privacy').modal('show');

        initListener();
    };


    // -------------------------------------------
    function initListener()
    {

        $(".form_policy_button").click(function()
        {
            $('#m_privacy').modal('toggle');
        });

        // close modal
        $(".modal_close").click(function()
        {
            $('#m_imprint').modal('hide');
            $('#m_privacy').modal('hide');
        });
    }
}

module.exports = modalModul;
