
function navigationModul()
{
    'use strict';

    var pagename = $("body").data("pagename");

    // -------------------------------------------
    this.init = function()
    {

        initListener();
    };

    // -------------------------------------------
    function initListener()
    {
        $(".navbar-brand").click(function()
        {
            $(".project_sub_menu").removeClass("show");
        });
        $(".nav-item").click(function(e)
        {
            //e.preventDefault();

            var index =  $(".nav-link", $(this) ).data("index");

            // show project sub menu
            if(pagename == "home" && index == 0)
            {
                e.preventDefault();
                $(".project_sub_menu").toggleClass("show");
                $(".wrapper").toggleClass("move");

                $(".nav-link", $(this)).toggleClass("active");
            }

            hideMobileNav();
        });
    }

    // -------------------------------------------
    function hideMobileNav()
    {
        $(".navbar-collapse").removeClass("show");
    }

    // -------------------------------------------
    $(window).on('resize', function()
    {

    });
    $(window).resize();

    // -------------------------------------------
    $(window).on('scroll', function()
    {
        hideMobileNav();
    });

}

module.exports = navigationModul;
