
function contactModul()
{
    'use strict';



    // -------------------------------------------
    this.init = function()
    {

        initForm();
    };

    // -------------------------------------------
    function initListener()
    {

    }

    function initForm()
    {
        resetForm();

        var formMessages = $('#form-messages');
        var forms = document.getElementsByClassName('needs-validation');

        var validation = Array.prototype.filter.call(forms, function(form)
        {
            form.addEventListener('submit', function(event)
            {
                if (form.checkValidity() === false)
                {
                    event.preventDefault();
                    event.stopPropagation();
                }
                else
                {
                    event.preventDefault();

                    //alert("OK");

                    var formData = $(form).serialize();

                    $.ajax({
                        type: 'POST',
                        url: "contact_form.php",
                        data: formData
                    }).done(function(response)
                    {
                        // Make sure that the formMessageBottom div has the 'success' class.
                        formMessages.removeClass('success');
                        formMessages.addClass('error');

                        form.classList.remove('was-validated');

                       //$("#form")[0].reset();

                        resetForm()

                        // Set the message text.
                        formMessages.text(response);

                        //alert('Send DONE');

                    }).fail(function(data)
                    {
                        // Make sure that the formMessageBottom div has the 'error' class.
                        formMessages.removeClass('success');
                        formMessages.addClass('error');

                        // Set the message text.
                        if (data.responseText !== '')
                        {
                            formMessages.text(data.responseText);
                        } else
                        {
                            formMessages.text('Oops! An error occured and your message could not be sent.');
                        }
                        //alert('Send FAIL');
                    });
                }

                form.classList.add('was-validated');

            }, false);

            // reset validation
            form.addEventListener('reset', function(event)
            {
                form.classList.remove('was-validated');
                formMessages.text('');

            }, false);
        });
    }

    function resetForm()
    {
        $('#name').val('');
        $('#firma').val('');
        $('#email').val('');
        $('#telefon').val('');
        $('#message').val('');
        $('#form_policy').prop('checked', false);
    }

    // -------------------------------------------
    $(window).on('resize', function()
    {

    });
}

module.exports = contactModul;
