
function officeModul()
{
    'use strict';



    // -------------------------------------------
    this.init = function()
    {
        $('a[href^="#"]').on('click',function(e) {
            e.preventDefault();
            var target = this.hash;
            var $target = $(target);
            $('html, body').stop().animate({
                'scrollTop': $target.offset().top
            }, 900, 'swing', function () {
                window.location.hash = target;
            });
        });

    };

    // -------------------------------------------
    function initListener()
    {

    }

    // -------------------------------------------
    $(window).on('resize', function()
    {

    });
}

module.exports = officeModul;
